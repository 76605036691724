import { Dictionary } from '@reduxjs/toolkit'
import { BindingType, ServiceParameter } from '@generated/api'
import { ServiceParamNameKeys } from '@components/TspPayee/utils/serviceUtils'
import { FieldEventLabel } from '@utils/gtm/serviceIdPayment/eventParams'
import { Param } from '@open-api/ump/catalog-manager'

export type ExtendedServiceParameter = Param & {
  mappedType: ServiceParamNameKeys
}

export type ServiceParams = Dictionary<string>

export const fieldToStatName: Dictionary<string> = {
  phoneNumber: FieldEventLabel.nomerTelefona,
  orderId: FieldEventLabel.nomerLicevogoScheta,
  cardNumber: FieldEventLabel.nomerKarty,
  validityPeriod: FieldEventLabel.srokDeistviyaKarty,
  CVC: FieldEventLabel.cvv,
  amount: FieldEventLabel.summaPlatezha,
}

export const BindingTypeToEventContext = {
  [BindingType.SAMSUNG_PAY]: 'samsung_pay',
  [BindingType.GOOGLE_PAY]: 'google_pay',
  [BindingType.APPLE_PAY]: 'apple_pay',
  [BindingType.ANONYMOUS_CARD]: 'bankovskaya_karta',

  [BindingType.UNKNOWN]: 'UNKNOWN',
  [BindingType.MTS_ACCOUNT]: 'MTS_ACCOUNT',
  [BindingType.EMONEY_ACCOUNT]: 'EMONEY_ACCOUNT',
  [BindingType.BOUND_CARD]: 'BOUND_CARD',
  [BindingType.GENERAL_CARD]: 'GENERAL_CARD',
  [BindingType.TECHNICAL_ACCOUNT]: 'TECHNICAL_ACCOUNT',
  [BindingType.VIRTUAL_CARD]: 'VIRTUAL_CARD',
  [BindingType.EXTERNALLY_TOKENIZED_CARD]: 'EXTERNALLY_TOKENIZED_CARD',
  [BindingType.VIRTUAL_CARD_LS]: 'VIRTUAL_CARD_LS',
  [BindingType.BANK_ACCOUNT]: 'BANK_ACCOUNT',
  [BindingType.VIRT_CREDIT_CARD]: 'VIRT_CREDIT_CARD',
  [BindingType.MASTERPASS_CARD]: 'MASTERPASS_CARD',
  [BindingType.VTS_TOKEN]: 'VTS_TOKEN',
  [BindingType.MDES_TOKEN]: 'MDES_TOKEN',
  [BindingType.VIRT_DEBIT_CARD_LS]: 'VIRT_DEBIT_CARD_LS',
  [BindingType.APPLE_PAY_TOKEN]: 'APPLE_PAY_TOKEN',
  [BindingType.GOOGLE_PAY_TOKEN]: 'GOOGLE_PAY_TOKEN',
  [BindingType.SAMSUNG_PAY_TOKEN]: 'SAMSUNG_PAY_TOKEN',
}

export enum ConfirmationType {
  OTP_SMS_CALLBACK = 'OTP_SMS_CALLBACK',
  OTP_PAYMENT_PAGE = 'OTP_PAYMENT_PAGE',
  FINISH_3DS = 'FINISH_3DS',
  FINISH_3DS2 = 'FINISH_3DS2',
}

export enum ContentType {
  'LOADING' = 'loading',
  'ERROR' = 'error',
  'CONTENT' = 'content',
}
