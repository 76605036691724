import { Dictionary } from '@reduxjs/toolkit'

export const TabPaymentsButtons = {
  bezAvtorizacii: 'bez_avtorizacii',
  vLichnomKabinete: 'v_lichnom_kabinete',
} as const

export type TabPaymentsButtonsType = typeof TabPaymentsButtons[keyof typeof TabPaymentsButtons]

export const SwitchPhoneOrderNumber = {
  poNomeruTelefona: 'po_nomeru_telefona',
  poNomeruLicevogoScheta: 'po_nomeru_licevogo_scheta',
} as const
export type SwitchPhoneOrderNumberType = typeof SwitchPhoneOrderNumber[keyof typeof SwitchPhoneOrderNumber]

export const LinkValues = {
  dogovorOferty: 'dogovor_oferty',
}

export type LinkValuesTypes = typeof LinkValues[keyof typeof LinkValues]

export const PaymentResultButtons: Dictionary<string> = {
  sozdatShablon: 'sozdat_shablon',
  podkluchitAvtoplatezh: 'podkluchit_avtoplatezh',
  povtoritPlatezh: 'povtorit_platezh',
  otpravitChek: 'otpravit_chek',
  telefon: 'telefon',
  pokazatDetaliOperacii: 'pokazat_detali_operacii',
} as const
export type PaymentResultButtonsType = typeof PaymentResultButtons[keyof typeof PaymentResultButtons]

export const BannerEvents: Dictionary<string> = {
  bannerShow: 'banner_show',
  bannerClick: 'banner_click',
}

export const FieldValueChanged: Dictionary<string> = {
  predzapolneno: 'predzapolneno',
  ruchnoiVvod: 'ruchnoi_vvod',
}
export type FieldValueChangedType = typeof FieldValueChanged[keyof typeof FieldValueChanged]

export const FieldEventLabel: Dictionary<string> = {
  nomerTelefona: 'nomer_telefona',
  nomerLicevogoScheta: 'nomer_licevogo_scheta',
  nomerKarty: 'nomer_karty',
  srokDeistviyaKarty: 'srok_deistviya_karty',
  cvv: 'cvv',
  summaPlatezha: 'summa_platezha',
}
export type FieldEventLabelType = typeof FieldEventLabel[keyof typeof FieldEventLabel]

export type BannerEventsType = typeof BannerEvents[keyof typeof BannerEvents]

export const FieldTooltipEventContext: Dictionary<string> = {
  nomerTelefona: 'nomer_telefona',
  nomerLicevogoScheta: 'nomer_licevogo_scheta',
}

export type FieldTooltipEventContextType = typeof FieldTooltipEventContext[keyof typeof FieldTooltipEventContext]

export const filterName: Dictionary<string> = {
  rekvizity: 'rekvizity',
}

export const PaymentMethod: Dictionary<string> = {
  bankovskayaKarta: 'bankovskaya_karta',
}
